(function () {
  let show_date = function () {

    var showDates = document.querySelectorAll(".js-1-show-date");

    showDates.forEach( function ( showDate ) {
      var date = new Date(window.fecha_evento);
      var months = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"];
      showDate.innerHTML = ("0" + date.getDate()).slice(-2) + ' de ' + months[date.getMonth()] + ' de ' + date.getUTCFullYear();
    } );

  }

  let updateCountdown = function (days_component, hours_component, minutes_component, seconds_component) {
    // Fecha objetivo (puede ser modificada según tus necesidades)
    var targetDate = new Date(window.fecha_evento);

    // Fecha actual
    var currentDate = new Date();

    // Diferencia en milisegundos entre las dos fechas
    var difference = targetDate - currentDate;

    // Cálculo de los días, horas, minutos y segundos restantes
    var days = Math.floor(difference / (1000 * 60 * 60 * 24));
    var hours = Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    var minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
    var seconds = Math.floor((difference % (1000 * 60)) / 1000);

    // Actualizar los elementos HTML con los valores correspondientes
    days_component.innerHTML = days;
    hours_component.innerHTML = hours;
    minutes_component.innerHTML = minutes;
    seconds_component.innerHTML = seconds;
  }

  show_date();

  var days_component = document.getElementById("days");
  var hours_component = document.getElementById("hours");
  var minutes_component = document.getElementById("minutes");
  var seconds_component = document.getElementById("seconds");


  if (days_component && hours_component && minutes_component && seconds_component) {
    setInterval(function () {
      updateCountdown(days_component, hours_component, minutes_component, seconds_component);
    }, 1000);
  }

})();

