(function () {

  const panels = document.querySelectorAll('.sw-horizontal-acordion-panel');
  if (!panels) return;

  const removeActiveClasses = () => {
      panels.forEach(panel => {
          panel.classList.remove('sw-active');
          var title = document.getElementById('sw-horizontal-acordion-title-'+panel.dataset.itemId);
          if (title) title.classList.remove('sw-active');
      })
  }

  panels.forEach(panel => {
      panel.addEventListener('click', () => {
          removeActiveClasses();
          panel.classList.add('sw-active');
          var title = document.getElementById('sw-horizontal-acordion-title-'+panel.dataset.itemId);
          if (title) title.classList.add('sw-active');
      })
  })

})();
